<template>
  <div class="pb-20">
    <div class="border border-solid border-blue-200 pt-4">
      <datatable
        :ajax="true"
        :ajax-pagination="true"
        :columns="users.columns"
        :query="users.query"
        :loading="users.loading"
        :url="users.url"
        :on-click="click"
        :filters="users.filters"
        :dropdown="false"
        sort="created_at"
        order="asc"
        ref="table"
      >
        <template #header>
          <search-form
            v-model="users.query"
            class="mb-5"
            placeholder="Search for user's name, status, etc."
            @submit="reloadTable"
          />
        </template>
      </datatable>
    </div>

    <modals-user
      :selected-user="users.selected"
      ref="userModal"
      @delete="userDeleted"
      @close="userModalClosed"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentFilter: null,
      users: this.$options.resource([], {
        url: `${this.$baseurl}/admin/personal/users/status/merchant-express-pre-approved`,
        query: '',
        selected: null,
        columns: [
          {
            name: 'id',
            th: 'User ID'
          },
          {
            name: 'name',
            th: 'Name',
            render: user => `${user?.name} ${user?.last_name}`
          },
          {
            name: 'merchant',
            th: 'Merchant',
            render: ({ merchant }) => merchant?.business_name
          },
          {
            name: "email",
            th: "Email",
            render: (user) => user?.official_email,
          },
          {
            name: "company",
            th: "Company",
            render: (user) => user?.profile?.employer,
          },
          {
            name: "salary",
            th: "Salary",
            render: (user) => user?.profile?.salary,
          },
          {
            name: "phone",
            th: "Phone",
            render: (user) => user?.phone_no,
          },
          {
            name: 'progress',
            th: 'Progress',
            render: user => {
              return this.progressIcons(user);
            },
            sortable: false
          },
          {
            name: 'status',
            th: 'Status',
            render: (user, status) => {
              return this.statusBadge(status, user);
            },
            sortable: false
          },
          {
            name: 'admins',
            th: 'Assigned To',
            render: user => {
              return this.assignedAdmin(user);
            },
            sortable: false
          },
          {
            name: 'updated_at',
            th: 'Date Approved',
            render: (user, updated_at) =>
              this.$moment(updated_at).format('MMM Do YYYY')
          },
          {
            name: 'created_at',
            th: 'Date Registered',
            render: (user, created_at) =>
              this.$moment(created_at).format('MMM Do YYYY')
          }
        ]
      })
    };
  },
  methods: {
    click(user) {
      this.users.selected = user;
      this.$refs.userModal.open();
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    userDeleted() {
      this.reloadTable();
    },
    userModalClosed() {
      this.users.selected = null;
    }
  }
};
</script>
